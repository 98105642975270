import { tns } from "@paybis/tiny-slider/src/tiny-slider";
import { getLocaleDirection } from "../helpers/helper";

document.addEventListener("DOMContentLoaded", initiateTrustpilotSlider);
document.body.addEventListener('lazy-block-loaded', ({ detail: blockName }) => {
  if (blockName !== 'trustpilot' && blockName !== 'walletTrustpilot') {
    return;
  }

  initiateTrustpilotSlider();
});

function initiateTrustpilotSlider() {
  const reviews = document.querySelectorAll('.trust-reviews-display__item:not(.loading__item)');
  if (reviews.length === 0) {
    return;
  }
  tns({
    container: ".trust-reviews-display .VueCarousel",
    autoplay: true,
    items: 1,
    slideBy: 1,
    nav: true,
    navPosition: "bottom",
    nextButton: ".VueCarousel-navigation-next",
    prevButton: ".VueCarousel-navigation-prev",
    controls: true,
    controlsContainer: false,
    textDirection: getLocaleDirection(),
    responsive: {
      1000: {
        items: 3,
        slideBy: 3,
      },
      768: {
        items: 2,
        slideBy: 2,
      },
    },
  });
}
